@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.notification {
  max-width: 600px;
  width: 100%;
  padding: 20px;
  position: relative;
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  user-select: none;
  margin-bottom: -59px;

  transition: margin-bottom 0.3s;
}

.notification.error {
  background-color: #E12626;
  color: #FDFDFD;
}

.notification.success {
  background-color: #0CAA1B;
  color: #FDFDFD;
}

.notification.warning {
  background-color: #E1AA1B;
  color: #FDFDFD;
}

.notification.info {
  background-color: var(--secondary-color);
  color: #FDFDFD;
}

.notification .progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: right 1s linear;
}