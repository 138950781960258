@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100%;

  background-color: rgba(27, 27, 27, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup-container {
  font-family: 'Karla', sans-serif;
  background-color: #FFFFFF;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 60px 20px 50px;
  min-width: 340px;
  max-width: 95vw;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-container form {
  width: 100%;
  /*display: contents;*/
  overflow-y: scroll;
}

.popup-container ::-webkit-scrollbar {
  -webkit-appearance: none;
}

.popup-container ::-webkit-scrollbar:vertical {
  width: 10px;
}

.popup-container ::-webkit-scrollbar:horizontal {
  height: 0;
}

.popup-container ::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #E2E9F0;
}

.popup-container ::-webkit-scrollbar-track-piece {
  -webkit-appearance: none;
}

.popup-headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.popup-popupTitle {
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: 700;
  font-size: 1.5rem;
  align-self: flex-start;
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
}

#closePopupButton {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 20px;
  position: absolute;
  width: 22px;
  right: -40px;
}

#svgClosePopup {
  height: 100%;
  width: 100%;
}

.svgClosePopup-1 {
  fill: var(--secondary-color);
}

.svgClosePopup-2 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 1;
}

.popup-popupButton {
  background-color: var(--secondary-color);
  border: none;
  border-radius: 4px;
  color: #FBFBFB;
  cursor: pointer;
  padding: 11px 23px 11px 23px;
  font-size: 0.6875rem;
  text-transform: uppercase;
  font-family: 'Karla', sans-serif;
  font-weight: 700;
}

.popup-popupButton.red {
  background-color: #E12626;
}

.popup-popupButton.green {
  background-color: #0CAA1B;
}

.popup .popup-popupButton.disabled {
  background-color: #6A6A6A;
  pointer-events: none;
  cursor: default;
}

.popup .popup-linkContainer {
  margin: 16px 0;
}

.popup .popup-linkContainer a {
  color: var(--secondary-color);
  margin: 0 59px 0 0;
}

.popup-section {
  border-top: 1px solid #E2E9F0;
  padding: 18px 0;
  display: flex;
  width: 100%;
}

.popup-section:not(.popup-buttonSection) {
  display: grid;
  max-width: 60vw;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.popup-buttonSection {
  padding-top: 34px;
  justify-content: space-evenly;
}

.popup-section:first-child {
  padding-top: 34px;
}

.popup-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup-row {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.popup-row h1 {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 1.875rem;
  font-weight: 700;
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
}

.popup-row h3 {
  margin-top: 32px;
  font-size: 1rem;
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
}

.popup-inputContainer {
  margin: 0 0 19px 0;
  width: 100%;
}

.popup-section h2 {
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
}

.popup-inputContainer input,
.popup-inputContainer select {
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  width: 100%;
  max-width: 240px;
  height: 42px;
  padding: 0 11px 0 11px;
}

.popup-inputContainer [type="color"] {
  cursor: pointer;
}

.popup-inputContainer [type="file"] {
  padding: 11px;
  cursor: pointer;
}

.popup-inputContainer ::file-selector-button {
  display: none;
}

.login-popup {
  z-index: 1;
}

@media only screen and (max-width: 690px) {
  .popup .popup-column {
    width: 100%;
  }

  .popup .popup-section {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 420px) {
  .popup .popup-container {
    padding: 20px 20px 10px 20px;
    min-width: 95vw;
  }

  #closePopupButton {
    right: 0;
  }

  .popup-section,
  .popup-buttonSection,
  .popup-section:first-child {
    padding: 10px 0;
  }

  .popup .popup-linkContainer {
    margin: 10px 0;
  }

  .popup .popup-linkContainer a {
    margin: 0;
  }

}

@media only screen and (max-width: 370px) {
  .popup .popup-linkContainer a {
    margin: 0;
  }
}