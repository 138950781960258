.quotationPopup-partsContainer-parts h4{
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--primary-color);
  margin: 0;
}
.quotationPopup-partsContainer-imageContainer{
  margin: 0 20px 0 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.quotationPopup-partsContainer-imageContainer img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.quotationPopup-partsContainer-informationContainer{
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
}
.quotationPopup-partsContainer-general,
.quotationPopup-partsContainer-operations,
.quotationPopup-partsContainer-quality{
  width: 50%;
}
.quotationPopup-partsContainer-removedOperations{
  margin: 20px 0 0 0;
  width: 100%;
}
.quotationPopup-partsContainer-priceAmountTotalWrapper{
  width: 35%;
  display: flex;
}
.quotationPopup-partsContainer-priceAmountTotalWrapper .quotationPopup-partsContainer-price,
.quotationPopup-partsContainer-priceAmountTotalWrapper .quotationPopup-partsContainer-amount, 
.quotationPopup-partsContainer-priceAmountTotalWrapper .quotationPopup-partsContainer-total{
  width: 33%;
}
.quotationPopup-partsContainer-mobileText{
  display: none;
}

.quotationPopup-partsContainer-informationNoteContainer{
  width: 100%;
}
.quotationPopup-partsContainer-noteContainer{
  width: 100%;
  margin: 10px 0 0 0;
}
.quotationPopup-partsContainer-noteContainer input{
  width: 100%;
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  padding: 5px 11px 5px 11px;
}
.quotationPopup-part-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 5px 0;
}
.quotationPopup-part-header h3{
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  color: var(--primary-color);
}
.quotationPopup-part-main{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 30px 0;
}
.quotationPopup-part-moreTopMargin{
  margin: 20px 0 0 0;
}

.quotationPopup-partsContainer-amount input{
  width: 50%;
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  padding: 2px;
  margin: 0 5px 0 0;
  text-align: center;
}
@media only screen and (max-width: 800px) {
  .quotationPopup-partsContainer-mobileText{
    display: inline
  }
  .quotationPopup-part-main{
    flex-direction: column;
  }
  .quotationPopup-partsContainer-priceAmountTotalWrapper{
    width: 100%;
    justify-content: space-between;
  }

  .quotationPopup-partsContainer-amount input{
    width: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .quotationPopup-partsContainer-priceAmountTotalWrapper{
    flex-direction: column;
  }
  .quotationPopup-partsContainer-priceAmountTotalWrapper .quotationPopup-partsContainer-price, 
  .quotationPopup-partsContainer-priceAmountTotalWrapper .quotationPopup-partsContainer-amount, 
  .quotationPopup-partsContainer-priceAmountTotalWrapper .quotationPopup-partsContainer-total{
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .quotationPopup-partsContainer-parts{
    display: block;
    width: 100%;
  }
  .quotationPopup-partsContainer-priceAmountTotalWrapper .quotationPopup-partsContainer-price, 
  .quotationPopup-partsContainer-priceAmountTotalWrapper .quotationPopup-partsContainer-amount, 
  .quotationPopup-partsContainer-priceAmountTotalWrapper .quotationPopup-partsContainer-total{
    width: auto;
  }
  .quotationPopup-partsContainer-informationContainer{
    justify-content: space-between;
  }
  .quotationPopup-partsContainer-imageContainer{
    justify-content: center;
    margin: 0;
  }
  .quotationPopup-partsContainer-mobileText{
    display: inline
  }
}