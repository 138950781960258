.previewPopup {
  width: 80vw;
  height: 80vh;
}

.previewPopup .popup-section {
  height: 100%;
  max-width: 80vw;
}

.partPreviewContainer-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
  margin-bottom: 8px;
}

.previewPopup .popup-section {
  /* border: none; */
  padding: 0;
}

.previewPopup .modelPreview .switchContainer {
  top: 20px;
  right: 10px;
}

.previewPopup .modelPreview {
  padding: 0 20px;
  border: none;
}

.previewPopup .modelPreview .react-transform-wrapper {
  height: 100%;
}