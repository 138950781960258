.percentageInput {
  position: relative;

  width: 100%;
  max-width: 240px;
}

.percentageInput:after {
  content: '%';
  display: block;

  position: absolute;
  top: 0;
  right: 0;

  padding-right: 35px;
  line-height: 42px;

  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);

  pointer-events: none;
}