.contactPopup .contactPopup-inputZipCode {
  width: 35%;
  margin-right: 5%;
}

.contactPopup .contactPopup-inputCity {
  width: 60%;
}

.contactPopup .popup-checkboxInput:not(:last-child) {
  margin-right: 20px;
}

@media only screen and (max-width: 690px) {

  .contactPopup .popup-claimsContainer,
  .contactPopup .popup-documentsContainer {
    display: flex;
    flex-direction: column;
  }
}