.uploadInput {
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  width: 100%;
  max-width: 240px;
  height: 42px;
  padding: 0 11px 0 11px;

  display: block;
  line-height: 42px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.uploadInput input {
  display: none;
}