.uploadProgress {
  width: 100%;
  max-width: 420px;
  margin-bottom: 40px;
  display: flex;
}

.uploadProgress .icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.uploadProgress .icon svg {
  display: block;
  height: 62px;
}

.uploadProgress .info {
  margin-left: 26px;
  flex-grow: 1;
  min-width: 0;

  display: flex;
  flex-direction: column;
}

.uploadProgress .name {
  flex-grow: 1;
  min-width: 0;

  color: var(--primary-color);
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: bold;

  display: flex;
  align-items: center;
}

.uploadProgress .name span {
  flex-grow: 1;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.uploadProgress .progress {
  border: 1px solid #D5E1FF;
  display: flex;
  flex-basis: 13px;
  margin-top: 10px;
}

.uploadProgress .progress::before {
  content: '';
  display: block;
  flex-grow: 1;
  background-color: #2444a3;
}

.uploadProgress .statusMessage {
  font-size: 12px;
  margin-top: 10px;
}

.uploadProgress .statusMessage.error {
  color: #E12626;
}

.uploadProgress .statusMessage.warning {
  color: #E1AA1B;
}