.filterPopup .popup-section {
  display: grid;
  max-width: 60vw;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-column-gap: 20px;
}

.popup .filterPopup .popup-column {
  margin-left: 0;
}

.popup-inputContainer .reactSelect input {
  height: 28px;
}