.tappingPopup .wcTable {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.tappingPopup input,
.tappingPopup select {
  width: 100%;
}

.tappingPopup input[type="file"] {
  display: none;
}

.tappingPopup .error {
  border-color: #E12626;
}