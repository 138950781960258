@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.partsList {
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.partsList .partsList-header {
  background-color: var(--primary-color);
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
}

.partsList .partsList-header .group {
  display: flex;
}

.upload-svg {
  width: 18px;
}

.filter-svg {
  width: 18px;
  margin: 4px 0;
}

.partsList .partFileUpload {
  display: none;
}

.partsList .partFileUpload+.generalButton {
  margin-right: 0;
}

.partsList .partFileLabel {
  padding: 5px 0;
  border-radius: 0 0 5px 5px;
  background-color: var(--secondary-color-light);
}

.partsList .partFileLabel:empty {
  padding: 0;
}

.partsList .partFileLabel div {
  padding: 10px;
}

.generalButton {
  border: none;
  background: none;
  background-color: var(--secondary-color);
  padding: 9px 12px 9px 12px;
  display: flex;
  align-items: center;

  color: #FBFBFB;
  font-family: 'Karla', sans-serif;
  font-weight: 700;
  font-size: 0.6875rem;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  margin-right: 16px;
}

.generalButton svg {
  margin-right: 11px;
}

.partsList-container {
  border-right: 1px solid #E2E9F0;
  border-left: 1px solid #E2E9F0;
  min-height: 60vh;
}

.partsList-container-noUpload {
  border: 1px solid #E2E9F0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--primary-color);
  padding: 40px 0 0 30px;
  height: 60vh;
}

.partsList-noUpload {
  margin: 0;
  padding: 0;
}

.partsList-controlsContainer {
  padding: 0 37px 0 31px;
  height: 50px;
  width: 58%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partsList-checkboxContainer {
  display: flex;
  align-items: center;
}

.partsList-checkboxContainer svg {
  margin-left: 5px;
  cursor: pointer;
}

#delete-btn {
  border: none;
  background: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#delete-btn:hover {
  background-color: #E2E9F0;
}

#delete-btn:hover .delete-btn-svg-1 {
  fill: var(--secondary-color);
}

#delete-btn-svg {
  height: 18px;
}

.delete-btn-svg-1 {
  fill: #2444a3;
}

.partsList-mainContainer {
  margin: 0 32px 0 16px;
  grid-template-columns: calc(65% - 20px) 35%;
  grid-gap: 20px;

  display: grid;
}

.partsList-list {
  overflow: scroll;
  user-select: none;
  max-height: 60vh;
  min-height: 100%;
  padding-bottom: 20px;
}

.partsList-list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.partsList-list::-webkit-scrollbar:vertical {
  width: 10px;
}

.partsList-list::-webkit-scrollbar:horizontal {
  height: 0;
}

.partsList-list::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #E2E9F0;
}

.partsList-list::-webkit-scrollbar-track-piece {
  -webkit-appearance: none;
}

.partsList .partsList-manufactureSettingsContainer .title {
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--primary-color);
  border-bottom: 1px solid #E2E9F0;
  width: 100%;
  padding: 12px 0 6px 0;
  margin: 0 0 12px 0;
}

.partsList-manufactureSettingsContainer .manufactureContainer {
  display: flex;
  flex-direction: column;
}

.partsList-manufactureSettingsContainer .manufactureContainer span {
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 0.875rem;
}

.partsList-manufactureSettingsContainer .manufactureContainer input,
.partsList-manufactureSettingsContainer .manufactureContainer select {
  width: 100%;
  max-width: 164px;
  height: 42px;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  background-color: transparent;
  padding: 0 17px;
  margin: 4px 0 12px 0;
  font-family: 'Karla', sans-serif;
}

.partsList-manufactureSettingsContainer .manufactureContainer .reactSelect {
  width: 100%;
  max-width: 164px;
}

.partsList-manufactureSettingsContainer .manufactureContainer .reactSelect input {
  height: 28px;
}

.partsList-manufactureSettingsContainer .additionalOperations {
  display: flex;
}

.partsList-manufactureSettingsContainer .tappingOption {
  margin: 0 20px 8px 0;
  display: flex;
  flex-direction: row;

  text-decoration: underline;
  color: var(--primary-color);
  cursor: pointer;
}

.partsList-manufactureSettingsContainer .tappingOption span::after {
  content: ": "
}

.partsList-manufactureSettingsContainer .selectsContainer span {
  display: block;
}

.partsList-manufactureSettingsContainer .selectsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.partsList-manufactureSettingsContainer .operationsContainer {
  margin-top: 20px;
}

.partsList-manufactureSettingsContainer .operationsContainer>* {
  margin-bottom: 10px;
}

.partsList-manufactureSettingsContainer .operationsContainer> :before,
.partsList-manufactureSettingsContainer .operationsContainer> :after {
  content: '';
  display: table;
  clear: both;
}

.partsList-manufactureSettingsContainer .operationsContainer input:not([type="checkbox"]),
.partsList-manufactureSettingsContainer .operationsContainer select {
  width: 100%;
  max-width: 225px;
  height: 42px;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  background-color: transparent;
  padding: 0 17px;
  margin: 4px 10px 8px 0;
  font-family: 'Karla', sans-serif;
}

.partsList-manufactureSettingsContainer .operationsContainer .checkboxContainer,
.partsList-manufactureSettingsContainer .tappingOption {
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0 20px 8px 0;
  display: flex;
  align-items: center;
  float: left;
}

.partsList-manufactureSettingsContainer .operationsContainer .checkboxContainer.disabled {
  opacity: 0.5;
}

.partsList-manufactureSettingsContainer .buttonContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.partsList .unattendedContainer {
  margin-right: 20px;
  color: #fff;
}

.partsList .unattendedContainer .checkmark {
  border-color: #fff;
}

.partsList .partsList-footer {
  background-color: var(--primary-color);
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 37px;
}