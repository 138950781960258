body .popup-section.calculationOverview-container {
  display: flex;
  overflow: hidden;
  max-width: 80vw;
}

.calculationOverview-container h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  color: var(--primary-color);
  margin: 0 0 7px 0;
}

.calculationOverview-container .border {
  position: relative;
  border: 1px solid #E2E9F0;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.calculationOverview-container .specification {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}

.calculationOverview-container .specification .total {
  font-weight: bold;
}

.calculationOverview-container .divider {
  grid-column: 1 / 3;
  width: 100%;
  height: 1px;
  background-color: #E2E9F0;
}

.calculationOverview-container .nestingPreview {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.calculationOverview-container .priceSpecification {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
}

.calculationOverview-container .preview {
  flex-grow: 1;
  overflow: hidden;
}

.calculationOverview-container .preview img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.calculationOverview-container .preview .loadingContainer {
  width: 100%;
  height: 100%;
}

.calculationOverview-container .plates {
  width: 55vw;
  flex-basis: 20vh;
  flex-shrink: 0;
  overflow: scroll;
}

.calculationOverview-container .plates .wcTable {
  grid-template-columns: repeat(6, auto);
}

.calculationPopup form {
  display: flex;
}

.calculationPopup .react-transform-wrapper {
  width: 100%;
  height: 100%;
}

.calculationPopup .react-transform-component {
  width: 100%;
  height: 100%;
}