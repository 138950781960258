.authenticationPage {
  background-image: url('./authentication-background.png');
  background-size: cover;
  background-position: center;
}

.authenticationPage .popup {
  z-index: 1;
}

.authenticationPage .popup-section {
  max-width: 560px;
}

.login-popup .popup-inputContainer,
.popup.passwordRequest .popup-inputContainer {
  max-width: 240px;
}

.login-popup .popup-column,
.popup.passwordRequest .popup-column {
  align-items: center;
}