.quotationPopup-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
}

.quotationPopup-header h2 {
  font-size: 1.125rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: var(--primary-color);
  margin: 0 15px 0 0;
}

.quotationPopup-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  overflow: hidden;
  border-top: 1px #e2e9f0 solid;
  padding: 40px 0 0 0;
}

.quotationPopup-partsContainer {
  width: 55vw;
  margin: 0 3vw 0 0;

  display: flex;
  flex-direction: column;
}

.quotationPopup-informationContainer {
  width: 25vw;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 10px 10px 0;
}

.quotationPopup-informationContainer-accountContainer {
  margin: 0 0 20px 0;
}

.quotationPopup-informationContainer h2,
.quotationPopup-partsContainer h2 {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  color: var(--primary-color);
  margin: 0 0 7px 0;
}

.quotationPopup-informationContainer .horizontalLine,
.quotationPopup-partsContainer .horizontalLine {
  width: 100%;
  height: 1px;
  background-color: #e2e9f0;
  margin: 0 0 15px 0;
}

.quotationPopup-informationContainer-calculationContainer .horizontalLine {
  margin: 8px 0 8px 0;
  grid-column: 1/3;
}

.fullWidth input {
  width: 100%;
  height: 32px;
}

.smallWidth {
  display: flex;
}

.smallWidth select, .smallWidth .quotationPopup-deliveryTimes {
  flex: 1;
  max-width: 50%;
}

.smallWidth select:not(:first-child) {
  margin-left: 20px;
}

.accountInformationContainer {
  width: 100%;
  font-size: 0.875rem;
}

.accountInformationContainer-rowContainer {
  display: flex;
  flex: row;
}

.accountInformationContainer-information {
  font-weight: 700;
  min-width: 70px;
}

.accountInformationContainer-data {
  margin: 0 0 0 10px;
  width: 240px;
}

.quotationPopup-informationContainer-shippingContainer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.quotationPopup-informationContainer-calculationContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 20px;
  margin: 20px 0 0 0;
}

.calculationContainer-information {
  font-weight: 700;
  font-size: 1.125rem;
  font-family: "Poppins", sans-serif;
  text-align: right;
  margin: auto 0;
}

.calculationContainer-data {
  position: relative;
  font-size: 0.875rem;
  margin: auto 0;
}

.calculationContainer-data .inputPrefix {
  position: absolute;

  font-size: 0.875rem;
  font-family: "Karla", sans-serif;
  color: var(--primary-color);
  padding: 3px 0 0;
}

.calculationContainer-data input {
  width: 100%;
  font-size: 0.875rem;
  font-family: "Karla", sans-serif;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid #e2e9f0;
  border-radius: 4px;
  padding: 2px 0;
  margin: 0 0 0 -5px;
}

.calculationContainer-data .inputPrefix+input {
  padding-left: 17px;
}

.quotationPopup-partsContainer-headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px 0 0;
}

.quotationPopup-partsContainer-parts {
  width: 65%;
  display: flex;
  flex-direction: row;
}

.quotationPopup-partsContainer-price,
.quotationPopup-partsContainer-amount,
.quotationPopup-partsContainer-total {
  width: 10%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.quotationPopup-partsContainer-amount {
  text-align: center;
}

.quotationPopup-partsContainer-total {
  text-align: end;
}

.quotationPopup-partListContainer {
  overflow-y: scroll;
  box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.1);
  flex: 1;
  padding: 0 10px 0 0;
}

.calculationContainer-buttonContainer-desktop,
.calculationContainer-buttonContainer-mobile {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;
  grid-column: 1/3;
}

.calculationContainer-buttonContainer-desktop button:not(:first-child),
.calculationContainer-buttonContainer-mobile button:not(:first-child) {
  margin: 0 0 0 27px;
}

.calculationContainer-buttonContainer-mobile {
  display: none;
}

#delete-btn {
  border: none;
  background: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#delete-btn:hover {
  background-color: #e2e9f0;
}

#delete-btn:hover .delete-btn-svg-1 {
  fill: var(--secondary-color);
}

#delete-btn-svg {
  height: 18px;
}

.delete-btn-svg-1 {
  fill: #2444a3;
}

.informationPopupContainer {
  position: relative;
  grid-column: 1/ 3;
}

.informationPopupContainer-button {
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 10;
}

#informationButton {
  width: 10px;
  height: 10px;
}

.manufacturingInformationContainer {
  position: absolute;
  bottom: 15px;
  right: 0;
  border: 1px solid #e2e9f0;
  border-radius: 4px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
  padding: 20px;
  background-color: #fff;
}

.manufacturingInformationContainer .row {
  display: flex;
  flex-direction: row;
}

.manufacturingInformationContainer :not(:last-child) {
  margin-bottom: 9px;
}

.manufacturingInformationContainer :not(:first-child) {
  margin-top: 9px;
}

.manufacturingInformationContainer .property {
  min-width: 200px;
  margin: 0;
}

.manufacturingInformationContainer .value {
  min-width: 80px;
  margin: 0;
}

.manufacturingInformationContainer .total {
  font-weight: 700;
}

@media only screen and (max-width: 1279px) {
  .quotationPopup-container {
    flex-direction: column;
    justify-content: flex-start;
  }

  .quotationPopup-partListContainer {
    box-shadow: none;
  }

  .quotationPopup-partsContainer {
    width: 100%;
    order: 2;
  }

  .quotationPopup-informationContainer {
    width: 100%;
    order: 1;
    padding: 0 0 40px 0;
  }

  .quotationPopup-informationContainer-accountContainer,
  .quotationPopup-informationContainer-shippingContainer,
  .quotationPopup-informationContainer-calculationContainer {
    width: 30%;
  }

  .quotationPopup-informationContainer {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 50px 0;
  }

  .quotationPopup-informationContainer-shippingContainer-row {
    flex-direction: column;
  }

  .quotationPopup-informationContainer-shippingContainer-row select {
    max-width: 100%;
    margin-left: 0 !important;
    flex: auto;
  }

  .quotationPopup-container .popup-inputContainer {
    margin: 0;
  }

  .smallWidth select {
    width: 150px;
    margin: 0 0 19px 0;
  }

  .quotationPopup-informationContainer-calculationContainer {
    margin: 30px 0 0 0;
  }

  .accountInformationContainer {
    margin: 19px 0 0 0;
  }

  .calculationContainer-buttonContainer-mobile {
    display: flex;
    order: 3;
  }

  .calculationContainer-buttonContainer-desktop {
    display: none;
  }
}

@media only screen and (max-width: 950px) {
  .quotationPopup-informationContainer {
    flex-direction: column;
    margin: 0;
  }

  .quotationPopup-informationContainer-accountContainer,
  .quotationPopup-informationContainer-shippingContainer,
  .quotationPopup-informationContainer-calculationContainer {
    width: 100%;
  }

  .quotationPopup-informationContainer-shippingContainer-row {
    flex-direction: row;
  }

  .quotationPopup-informationContainer-shippingContainer-row select {
    max-width: 100%;
    flex: auto;
  }

  .quotationPopup-informationContainer-shippingContainer-row select:not(:first-child) {
    margin-left: 19px !important;
  }
}

@media only screen and (max-width: 800px) {
  .quotationPopup-part-moreTopMargin {
    min-width: 140px;
  }

  .quotationPopup-partsContainer-price,
  .quotationPopup-partsContainer-amount,
  .quotationPopup-partsContainer-total {
    width: 100%;
    text-align: left;
  }

  .quotationPopup-container {
    padding: 10px 0 0 0;
  }

  .quotationPopup-partsContainer-parts {
    width: 100%;
  }

  .quotationPopup-informationContainer-accountContainer,
  .quotationPopup-informationContainer-shippingContainer,
  .quotationPopup-informationContainer-calculationContainer {
    width: 100%;
  }

  .quotationPopup-partListContainer {
    padding: 0;
  }

  .popup .desktopText {
    display: none;
  }

  .quotationPopup-partsContainer-headerContainer {
    padding: 0;
  }

  .smallWidth {
    display: block;
  }

  .smallWidth select,
  .smallWidth select:not(:first-child) {
    margin: 0 0 10px 0;
    max-width: 100%;
  }

  .quotationPopup-informationContainer-shippingContainer-row select:not(:first-child) {
    margin-left: 0 !important;
  }
}