.toggleInput input {
  display: none;
}

.toggleInput div {
  background-color: #dadada;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  width: 75px;
  height: 42px;

  transition: background-color 0.3s;
  transition-timing-function: cubic-bezier(.57, 2.2, .36, .15);
}

.toggleInput div:after {
  content: '';
  display: block;
  background-color: #ffffffee;
  margin: 4px;
  width: 32px;
  height: 32px;
  border-radius: 3px;

  transition: margin-left 0.3s;
  transition-timing-function: cubic-bezier(.57, 2.2, .36, .15);
}

.toggleInput :checked+div {
  background-color: var(--secondary-color-medium);
}

.toggleInput :checked+div:after {
  margin-left: 37px;
}