.uploadPopup {
  width: 920px;
  max-width: 100%;
  display: flex;
  overflow-y: hidden;
}

.uploadPopup .popup-column {
  align-items: stretch;
}

.uploadPopup .progressContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzone {
  display: block;
  text-align: center;
  position: relative;
  border: 1px dashed var(--secondary-color);
  border-radius: 20px;
  padding: 30px;
  margin-top: 20px;
}

.dropzone::after {
  content: attr(data-label);
  font-size: 24px;
  font-weight: bold;
  font-family: 'Poppins';
  text-transform: uppercase;
  color: var(--primary-color);
}

.dropzone input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.dropzone input:drop {
  opacity: 1;
}